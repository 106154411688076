body{
  background-color: black;
  margin: auto;
}

.wrapper{
  align-content: center;
  text-align: center;
}

.whitetext{
  color: white;
  font-size: 2.5em;
}

.sourceimg{
  width: 100%;
}
